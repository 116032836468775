import React, { FC, useRef, useState } from 'react';
import {
  Box,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  OutlinedInputProps,
  Theme,
} from '@mui/material';
import clsx from 'clsx';
import { VisibilityPassOffIcon } from './../../assets/newDesign/VisibilityPassOffIcon';
import { VisibilityPassIcon } from './../../assets/newDesign/VisibilityPassIcon';
import { makeStyles } from '@mui/styles';
import { SingUpPage } from '../../localization/en';
import { theme } from '../../../theme';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import { Tooltip } from '../table/Tooltip';
import QuestionMarkRoundedIcon from '@mui/icons-material/QuestionMarkRounded';

interface Props {
  webSite?: boolean;
  endAdornmentText?: string;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  input: (props) => ({
    paddingLeft: 0,
    overflow: 'hidden',
    position: 'relative',
    paddingRight: !!props.endAdornmentText ? '0px' : '',

    '& > input': {
      paddingLeft: props.webSite ? 0 : '16px',
    },
  }),
  labelWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    flexDirection: 'row',
  },
  inputLabel: {
    color: theme.palette.text.secondary,
    marginBottom: '10px',
    fontSize: '16px',
  },
  charchterlimit: {
    fontSize: '12px',
  },
  error: {
    color: 'red',
  },
  startAdornment: {
    width: '68px',
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '42px',
  },
  endAdornment: {
    width: 'min-content',
    height: '42px',
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: '0px 10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: '8px',
    },
  },
  Tooltip: {
    width: '14px',
    height: '14px',
    color: '#8B89A0',
    marginLeft: '12px',
    borderRadius: '50%',
    border: '1px solid',
    borderColor: '#8B89A0',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
}));

interface WithCharLimitProps {
  enabled?: boolean;
}

const WithCharLimit: FC<WithCharLimitProps> = ({ enabled, children }) => {
  if (!enabled) return <>{children}</>;

  return <Box position="relative">{children}</Box>;
};

interface CustomTextFieldProps {
  label: string | React.ReactNode;
  value: string;
  name: string;
  error: string | undefined;
  handleChange: (e: React.ChangeEvent<any>) => void;
  charcherLimit?: number;
  placeholder?: string;
  disabled?: boolean;
  isPasswordField?: boolean;
  labelClassName?: string;
  inputClassName?: string;
  type?: string;
  webSite?: boolean;
  rows?: number;
  handleTouched?: (fieldName: string, isTouched: boolean) => void;
  maxLength?: number;
  endAdornmentText?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
  autoComplete?: OutlinedInputProps['autoComplete'];
  labelTextClassName?: string;
  tooltip?: string;
}

export const CustomTextField: FC<CustomTextFieldProps> = ({
  label,
  value,
  error,
  handleChange,
  name,
  charcherLimit,
  disabled = false,
  isPasswordField = false,
  labelClassName,
  inputClassName,
  labelTextClassName,
  placeholder,
  type = 'text',
  webSite = false,
  rows = 1,
  handleTouched,
  maxLength,
  endAdornmentText,
  onBlur,
  autoComplete = 'none',
  tooltip,
}) => {
  const classes = useStyles({ webSite, endAdornmentText });
  const inputRef = useRef<HTMLInputElement>();
  const [isFocus, setIsFocus] = useState(false);
  const isError = charcherLimit ? !!error || value.length > charcherLimit : !!error;
  const handleFocus = () => {
    setIsFocus(true);
    if (handleTouched) {
      handleTouched(name, true);
    }
  };
  const handleBlur = (e: any) => {
    setIsFocus(false);
    onBlur && onBlur(e);
  };
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const [isShowPassword, setIsShowPassword] = useState(false);
  const showCharacterLimit = (isError || (charcherLimit && isFocus)) && charcherLimit;
  return (
    <>
      <div className={clsx(classes.labelWrapper, labelClassName)}>
        <InputLabel className={clsx(classes.inputLabel, labelTextClassName)} htmlFor="">
          {label}{' '}
          {tooltip && (
            <Tooltip
              padding="23px 25px"
              rounded="15px"
              title={tooltip}
              icon={<QuestionMarkRoundedIcon className={classes.Tooltip} />}
            />
          )}
        </InputLabel>
        {showCharacterLimit && (
          <InputLabel
            className={[classes.charchterlimit, isError ? classes.error : ''].join(' ')}
            htmlFor=""
          >
            {value.length + '/' + charcherLimit}
          </InputLabel>
        )}
      </div>

      {isPasswordField ? (
        <OutlinedInput
          onFocus={handleFocus}
          onBlur={(e) => handleBlur(e)}
          className={classes.input}
          placeholder={placeholder}
          id={name}
          name={name}
          value={value}
          error={!!error}
          onChange={handleChange}
          fullWidth
          disabled={disabled}
          type={isShowPassword ? 'text' : 'password'}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                name="showConfirmPassword"
                onClick={() => setIsShowPassword(!isShowPassword)}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {isShowPassword ? <VisibilityPassIcon /> : <VisibilityPassOffIcon />}
              </IconButton>
            </InputAdornment>
          }
          autoComplete={autoComplete}
          onKeyDown={(key) => {
            if (key.code === 'Enter') {
              inputRef.current?.blur();
            }
          }}
        />
      ) : (
        <WithCharLimit enabled={!!maxLength}>
          <OutlinedInput
            inputRef={inputRef}
            onFocus={handleFocus}
            onBlur={handleBlur}
            className={clsx(classes.input, inputClassName)}
            id={name}
            name={name}
            value={value}
            error={!!error}
            onChange={handleChange}
            type={type}
            fullWidth
            disabled={disabled}
            placeholder={placeholder}
            startAdornment={
              webSite ? (
                <InputAdornment position="start">
                  <Box className={classes.startAdornment}>{SingUpPage.CompanyWebsiteProtocol}</Box>
                </InputAdornment>
              ) : null
            }
            endAdornment={
              endAdornmentText ? (
                <InputAdornment position="end">
                  <Box className={classes.endAdornment}>{endAdornmentText}</Box>
                </InputAdornment>
              ) : null
            }
            rows={rows}
            multiline={rows > 1 ? true : false}
            autoComplete={autoComplete}
            onKeyDown={(key) => {
              if (key.code === 'Enter') {
                inputRef.current?.blur();
              }
            }}
          />
          {maxLength && (
            <Box
              style={{
                position: 'absolute',
                top: '-36px',
                right: '6px',
                color: error ? theme.palette.error.main : theme.palette.common.gray,
                fontSize: '14px',
                lineHeight: '26px',
                fontWeight: 400,
              }}
            >{`${value?.toString().length}/${maxLength}`}</Box>
          )}
        </WithCharLimit>
      )}

      {error && (
        <FormHelperText
          className={classes.errorWrapper}
          id="input-meta-title-helper-text"
          error
          sx={{ fontSize: '11.5px' }}
        >
          <ReportProblemRoundedIcon color="error" style={{ height: '16px', width: '16px' }} />
          {error}
        </FormHelperText>
      )}
    </>
  );
};
