import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { VenueCreationLoginPayload } from '../storeModels';
import { VenueCreationLoginOptions } from '../../api/models/auth';
import {
  getApiUrlForId,
  VenueCreationEndpoints,
  FiltersEndpoints,
  VenueDomainsEndpoints,
  ETSIntegrationEndpoints,
  VenueEndpoints,
} from '../../api/endpoints';
import { httpClient } from '../../services/httpClient/httpClient';
import {
  AdminVenueCreationOptions,
  EnterpriceRequestData,
  ETSCampaignData,
  RegisterVenueRequest,
  SVADMINRegisterVenueRequest,
} from '../../api/models/venueCreation';
import { VenueCreationStoreModel } from '../storeModels';
import { ETSVenueCreationPayload } from '../../api/models/venueCreation';
import { VenueAPIModel } from '../../api/models/venue';

const initialState: VenueCreationStoreModel = {
  program_payload: {
    programId: '',
    programName: '',
    campaignId: '',
    campaignStartDate: '',
    campaignName: '',
    campaignLogoUrl: '',
    campaignLogoData: '',
    campaignPrimaryColorHex: '',
    campaignSecondaryColorHex: '',
    defaultCampaignLandingUrl: '',
    participants: [],
    userAccounts: [],
    venueExists: true,
    venueId: null,
  },
  campaignId: '',
  filterUrls: [],
  filterUrlsLoading: false,
  subdomain: {
    isLoading: false,
    available: false,
    checked: false,
  },
  createdVenue: {
    payload: null,
    isLoading: false,
    created: false,
    initiated: false,
    error: null,
  },
  enterpriceRequest: {
    error: null,
    isLoading: false,
    created: false,
  },
};

export const Login = createAsyncThunk(
  'venueCreation/login',
  async (options: VenueCreationLoginOptions, { rejectWithValue }): Promise<any> => {
    try {
      //   return await httpClient.post<undefined, VenueAPIModel>({
      //     url: getApiUrlForId(VenueEndpoints.GetVenueByIdOrSubdomain, options.id),
      //     requiresToken: true,
      //   });

      return await new Promise((resolve, reject) => {
        resolve(VenueCreationLoginPayload);
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);
//GetEtsCompaignData
export const getEtsCompaignData = createAsyncThunk(
  'venueCreation/getEtsCompaignData',
  async (options: { id: string }, { rejectWithValue }): Promise<any> => {
    try {
      return await httpClient.get<undefined, ETSCampaignData>({
        url: getApiUrlForId(VenueCreationEndpoints.GetEtsCompaignData, options.id),
        requiresToken: true,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);
// VenueDomainsEndpoints.CheckAvailability

//ETSIntegrationEndpoints.CreateVenue ETSVenueCreationPayload
export const createVenue = createAsyncThunk(
  'venueCreation/createVenue',
  async (options: ETSVenueCreationPayload, { rejectWithValue }): Promise<any> => {
    try {
      return await httpClient.post<ETSVenueCreationPayload, VenueAPIModel>({
        url: ETSIntegrationEndpoints.CreateVenue,
        requiresToken: true,
        payload: options,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const createMarketVenue = createAsyncThunk(
  'venue/createMarketVenue',
  async (options: AdminVenueCreationOptions, { rejectWithValue }): Promise<any> => {
    try {
      return await httpClient.post<AdminVenueCreationOptions, VenueAPIModel>({
        url: VenueEndpoints.CreateVenue,
        requiresToken: true,
        payload: options,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

// TODO: remove check-availability related code
// venue name is created on the backend
export const checkAvailability = createAsyncThunk(
  'venueCreation/checkAvailability',
  async (options: { subdomain: string }, { rejectWithValue }): Promise<any> => {
    try {
      return await httpClient.post<{ subdomain: string }, { subdomainAvailable: boolean }>({
        url: VenueDomainsEndpoints.CheckAvailability,
        requiresToken: false,
        payload: options,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

// FiltersEndpoints.GenerateFilters
interface GenerateFiltersOptions {
  logoUrl: string;
  primaryColor: string;
  secondaryColor: string;
}
interface GenerateFiltersPayload {
  filterUrls: string[];
}
export const generateFilters = createAsyncThunk(
  'venueCreation/generateFilters',
  async (options: GenerateFiltersOptions, { rejectWithValue }): Promise<any> => {
    try {
      return await httpClient.post<GenerateFiltersOptions, GenerateFiltersPayload>({
        url: FiltersEndpoints.GenerateFilters,
        requiresToken: true,
        payload: options,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);
//RegisterVenueRequest
export const RegisterVenue = createAsyncThunk(
  'venue/registerVenue',
  async (options: RegisterVenueRequest, { rejectWithValue }): Promise<any> => {
    try {
      return await httpClient.post<RegisterVenueRequest, VenueAPIModel>({
        url: VenueEndpoints.RegisterVenue,
        requiresToken: false,
        payload: options,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const SVAdminRegisterVenue = createAsyncThunk(
  'venue/SVAdminregisterVenue',
  async (options: SVADMINRegisterVenueRequest, { rejectWithValue }): Promise<any> => {
    try {
      return await httpClient.post<SVADMINRegisterVenueRequest, VenueAPIModel>({
        url: VenueEndpoints.RegisterVenue,
        requiresToken: true,
        payload: options,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const EnterpriceRequest = createAsyncThunk(
  'venue/enterpriceRequest',
  async (options: EnterpriceRequestData, { rejectWithValue }): Promise<any> => {
    try {
      return await httpClient.post<any, any>({
        url: VenueEndpoints.RequestEnterprice,
        requiresToken: false,
        payload: options.payload,
        params: { captchaResponse: options.captchaResponse },
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

// TODO: simplify loading and error cases
const venueCreationSlice = createSlice({
  name: 'venueCreation',
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    setCampaignId: (state, { payload }) => {
      state.campaignId = payload.id;
    },
    resetIsSubdomainChecked: (state) => {
      state.subdomain.checked = false;
    },
    resetInitiated: (state) => {
      state.createdVenue.initiated = false;
    },
  },
  extraReducers: (reducersBuilder) => {
    // reducersBuilder.addCase(Login.pending, (state) => {

    // });
    reducersBuilder.addCase(getEtsCompaignData.fulfilled, (state, { payload }) => {
      state.program_payload = payload;
    });
    reducersBuilder.addCase(getEtsCompaignData.rejected, (state) => {
      state = initialState;
    });
    reducersBuilder.addCase(generateFilters.pending, (state, { payload }) => {
      state.filterUrlsLoading = true;
    });
    reducersBuilder.addCase(generateFilters.fulfilled, (state, { payload }) => {
      state.filterUrlsLoading = false;
      state.filterUrls = payload.filterUrls;
    });
    reducersBuilder.addCase(checkAvailability.fulfilled, (state, { payload }) => {
      state.subdomain.isLoading = false;
      state.subdomain.available = payload.subdomainAvailable;
      state.subdomain.checked = true;
    });
    reducersBuilder.addCase(checkAvailability.pending, (state, { payload }) => {
      state.subdomain.isLoading = true;
    });
    reducersBuilder.addCase(checkAvailability.rejected, (state, { payload }) => {
      state.subdomain.isLoading = false;
      state.subdomain.checked = true;
      state.subdomain.available = false;
    });
    reducersBuilder.addCase(createVenue.fulfilled, (state, { payload }) => {
      state.createdVenue.payload = payload;
      state.createdVenue.isLoading = false;
      state.createdVenue.created = true;
      state.createdVenue.initiated = false;
    });
    reducersBuilder.addCase(createVenue.pending, (state, { payload }) => {
      state.createdVenue.isLoading = true;
    });
    reducersBuilder.addCase(createVenue.rejected, (state, { payload }) => {
      state.createdVenue.isLoading = false;
      state.createdVenue.created = false;
      state.createdVenue.initiated = true;
    });
    reducersBuilder.addCase(createMarketVenue.fulfilled, (state, { payload }) => {
      state.createdVenue.payload = payload;
      state.createdVenue.isLoading = false;
      state.createdVenue.created = true;
      state.createdVenue.initiated = false;
    });
    reducersBuilder.addCase(createMarketVenue.pending, (state) => {
      state.createdVenue.isLoading = true;
    });
    reducersBuilder.addCase(createMarketVenue.rejected, (state, { payload }) => {
      state.createdVenue.isLoading = false;
      state.createdVenue.created = false;
      state.createdVenue.initiated = true;
    });
    reducersBuilder.addCase(RegisterVenue.rejected, (state, { payload }) => {
      state.createdVenue.isLoading = false;
      state.createdVenue.created = false;
      state.createdVenue.initiated = true;
      state.createdVenue.error = payload;
    });
    reducersBuilder.addCase(RegisterVenue.pending, (state) => {
      state.createdVenue.isLoading = true;
    });
    reducersBuilder.addCase(RegisterVenue.fulfilled, (state, { payload }) => {
      state.createdVenue.payload = payload;
      state.createdVenue.isLoading = false;
      state.createdVenue.created = true;
      state.createdVenue.initiated = false;
    });
    reducersBuilder.addCase(SVAdminRegisterVenue.rejected, (state, { payload }) => {
      state.createdVenue.isLoading = false;
      state.createdVenue.created = false;
      state.createdVenue.initiated = true;
      state.createdVenue.error = payload;
    });
    reducersBuilder.addCase(SVAdminRegisterVenue.pending, (state) => {
      state.createdVenue.isLoading = true;
    });
    reducersBuilder.addCase(SVAdminRegisterVenue.fulfilled, (state, { payload }) => {
      state.createdVenue.payload = payload;
      state.createdVenue.isLoading = false;
      state.createdVenue.created = true;
      state.createdVenue.initiated = false;
    });
    reducersBuilder.addCase(EnterpriceRequest.rejected, (state, { payload }) => {
      state.enterpriceRequest.isLoading = false;
      state.enterpriceRequest.error = payload;
    });
    reducersBuilder.addCase(EnterpriceRequest.pending, (state) => {
      state.enterpriceRequest.isLoading = true;
    });
    reducersBuilder.addCase(EnterpriceRequest.fulfilled, (state, { payload }) => {
      state.enterpriceRequest.isLoading = false;
      state.enterpriceRequest.created = true;
      state.enterpriceRequest.error = false;
    });
  },
});

export const { reset, setCampaignId, resetIsSubdomainChecked, resetInitiated } =
  venueCreationSlice.actions;
export default venueCreationSlice.reducer;
