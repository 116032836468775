import { Box, Theme, Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useMemo, useRef } from 'react';

import {
  castURLToHttps,
  removeProtocolFromUrl,
} from '../../../pages/signUp/signUpForm/SignUpForm.helper';

import { TextFieldComponent, TextFieldProps } from './TextField';

export const useURLTextFieldStyles = makeStyles((theme: Theme) => ({
  adornment: {
    borderRadius: '12px',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    width: '68px',
    height: '42px',
    marginLeft: '-14px',
    background: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

interface URLTextFieldProps extends TextFieldProps {
  value: string;
}

export const URLTextField: React.FC<URLTextFieldProps> = (props) => {
  const theme = useTheme();
  const styles = useURLTextFieldStyles();

  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const cursorPosition = e.target.selectionStart;

    if (e.target.value.length > 0) {
      e.target.value = castURLToHttps(e.target.value);
    }
    props.onChange?.(e);

    /**
     * HACK: set the cursor on the next tick to make sure that the value is updated
     * https://stackoverflow.com/a/74118256/7686245
     */
    Promise.resolve().then(() => {
      inputRef.current?.setSelectionRange(cursorPosition, cursorPosition);
    });
  };

  const value = useMemo(() => {
    return removeProtocolFromUrl(props.value);
  }, [props.value]);

  return (
    <TextFieldComponent
      {...props}
      inputRef={inputRef}
      helperText
      label=""
      inputProps={{
        style: {
          paddingLeft: 0,
        },
      }}
      adornment={
        <Box className={styles.adornment}>
          <Typography color={theme.palette.common.white}>https://</Typography>
        </Box>
      }
      onChange={handleChange}
      value={value}
    />
  );
};
