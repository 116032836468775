import { Button, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { FSModal } from '../../modal/Modal';
import { useStyles } from './DisconnectModal.styles';
import { DistributorsStrings } from '../../../localization/en';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const DisconnectModal: FC<Props> = ({ isOpen, onClose, onConfirm }) => {
  const styles = useStyles();

  return (
    <FSModal
      onClose={onClose}
      modalIsOpen={isOpen}
      rounded="16px"
      blur={true}
      width="449px"
      padding="0"
    >
      <Typography className={styles.subtitle}>{DistributorsStrings.RemoveConnection}</Typography>
      <Stack display={'flex'} justifyContent="center" direction="row" spacing={'12px'}>
        <Button className={styles.btn} onClick={onClose} variant="outlined">
          {DistributorsStrings.Cancel}
        </Button>
        <Button
          className={styles.btn}
          onClick={() => {
            onConfirm();
            onClose();
          }}
          variant="contained"
          color="primary"
        >
          {DistributorsStrings.ConfirmDisconnect}
        </Button>
      </Stack>
    </FSModal>
  );
};
