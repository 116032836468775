import { Box } from '@mui/material';
import { FC } from 'react';

import { SuperAdminAnalyticsBlock } from '../admin/performance/analyticsBlock/SuperAdminAnalyticsBlock';
import { VenuesTable } from '../admin/performance/tableSection/VenuesTable';
import { useSuperAdminAccountsPageStyles } from './SuperAdminAccountsPage.style';
import { isFirefox, isSafari } from 'react-device-detect';

interface IsuperAdminAccountsPageProps {
  //
}
const TOP_BAR = 96;
const STATISTIC_BLOCK = 188;
const SEARCH_BAR = 64;
const PAGINATION = 52;
const PADDING_BOTTOM = 50;
const SCROLL = 10;

export const SuperAdminAccountsPage: FC<IsuperAdminAccountsPageProps> = () => {
  const styles = useSuperAdminAccountsPageStyles();
  let top = TOP_BAR + STATISTIC_BLOCK + PADDING_BOTTOM + SCROLL;
  if (isSafari) {
    top = TOP_BAR + SEARCH_BAR + STATISTIC_BLOCK + PAGINATION + PADDING_BOTTOM;
  }
  if (isFirefox) {
    top = (TOP_BAR + SEARCH_BAR + STATISTIC_BLOCK + PAGINATION + PADDING_BOTTOM) * 1.0;
  }
  return (
    <Box
      className={styles.tableWrapper}
      sx={{
        '& #common-table-wrapper': {
          maxHeight: `calc(100vh - ${top}px)`,
          height: `calc(100vh - ${top}px)`,
        },
      }}
    >
      <VenuesTable>
        <Box sx={{ backgroundColor: '#f5f6f8', pt: '10px', pb: '15px' }}>
          <SuperAdminAnalyticsBlock />
        </Box>
      </VenuesTable>
    </Box>
  );
};
