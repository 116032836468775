import React, { FC, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useStyles } from './QuickStartTopBar.styles';
import { RoundedButton } from '../Button/RoundedButton';
import { QuickStartStrings } from '../../localization/en';
import { WebsiteRoutes } from '../../constants/routes';
import { useHistory, useLocation } from 'react-router-dom';
import { useVenueProperty } from '../../../hooks/useVenueProperty';
import { VenueProperties } from '../../../api/models/venue';
import { useTypedSelector } from '../../../store';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { QuickStartEventNames } from '../../constants/events/quickstartEvents';
import ArrowRightSmall from '../../assets/ArrowRightSmall';
import { VideosIconAnalytics } from '../../assets/newDesign/VideosIconAnalytics';

interface QuickStartTopBarProps {
  disableNextButton?: boolean;
  onNextClick?: () => void;
  accordionSubroutes?: Array<string>;
  isNextDefaultsDisabled?: boolean;
}

export const QuickStartTopBar: FC<QuickStartTopBarProps> = ({
  disableNextButton,
  onNextClick,
  accordionSubroutes,
  isNextDefaultsDisabled,
}) => {
  const styles = useStyles();
  const history = useHistory();
  const locationRoute = useLocation().pathname;
  const {
    venue: { id: venueId },
  } = useTypedSelector((state) => state.venue);
  const { trackEvent } = useTrackEvent();
  const { value: quickStartValues, update: updateQuickStartConfig } = useVenueProperty<
    VenueProperties['quickstart.config']
  >({
    property: 'quickstart.config',
  });

  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState<boolean>();
  const [visitedRoutes, setVisitedRoutes] = useState<string[] | null>(null);

  const getRoutesData = (visitedRoutes: string[], accordionSubroutes?: string[]) => {
    const isAllAccordionsVisited =
      accordionSubroutes?.every((accordionSubroute) => {
        return visitedRoutes.some((route) => route.includes(accordionSubroute));
      }) || false;

    return { isAllAccordionsVisited };
  };

  const [isRoutesLoaded, setIsRoutesLoaded] = useState(false);

  useEffect(() => {
    if (visitedRoutes === null && quickStartValues?.isQuickStartInProgress) {
      setVisitedRoutes(quickStartValues?.visitedRoutes || []);
      setIsRoutesLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quickStartValues?.isQuickStartInProgress]);

  useEffect(() => {
    if (venueId && quickStartValues && isRoutesLoaded) {
      const updatedVisitedRoutes = Array.from(new Set([...(visitedRoutes || []), locationRoute]));
      const { isAllAccordionsVisited } = getRoutesData(updatedVisitedRoutes, accordionSubroutes);

      updateQuickStartConfig({
        ...quickStartValues,
        currentRoute: history.location.pathname,
        visitedRoutes: updatedVisitedRoutes,
      });

      setVisitedRoutes(updatedVisitedRoutes);
      setIsNextButtonDisabled(accordionSubroutes && !isAllAccordionsVisited);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationRoute, venueId, accordionSubroutes, isRoutesLoaded]);

  const handleBack = () => {
    trackEvent(QuickStartEventNames.quick_start_back_button_click);

    history.push(WebsiteRoutes.QuickStartWelcome);
  };

  const handleSave = async () => {
    await onNextClick?.();

    if (isNextDefaultsDisabled) {
      return;
    }

    history.push(WebsiteRoutes.QuickStartComplete);
    trackEvent(QuickStartEventNames.QuickStartSavePersonalization);
  };

  return (
    <Box className={styles.container}>
      <RoundedButton
        title={QuickStartStrings.Back}
        className={styles.navBtn}
        outlined
        onClick={handleBack}
      />
      <Box className={styles.titleWrapper}>
        <VideosIconAnalytics width="30px" height="30px" />
        <Typography className={styles.title}>{QuickStartStrings.Setup}</Typography>
      </Box>
      <RoundedButton
        title={QuickStartStrings.Save}
        className={styles.navBtn}
        endIcon={<ArrowRightSmall />}
        onClick={handleSave}
        disabled={disableNextButton || isNextButtonDisabled}
      />
    </Box>
  );
};
