import * as Yup from 'yup';
import { ErrorsStrings } from '../../../common/localization/en';
import { getYupValidationWithPhone } from '../../../common/validation/commonValidation';
import { trimAndLowercase } from '../../../services/utilities';

const required = ErrorsStrings.FieldIsRequired;
const str = Yup.string();
const email = str.email(ErrorsStrings.WriteCorrectEmail).required(required);

export const getValidationSchemaSignUpForm = getYupValidationWithPhone(
  {
    companyName: str.min(3, ErrorsStrings.Minlength3characters).trim().required(required),
    practiceName: str.min(3, ErrorsStrings.Minlength3characters).trim().required(required),
    companyWebsite: str
      .matches(
        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9-]+([.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gim,
        'Your company URL must be a valid URL',
      )
      .required(required), //use the required ‘https://’
    address: str
      .min(5, ErrorsStrings.Minlength5characters)
      .max(48, ErrorsStrings.Maxlength48characters)
      .trim()
      .required(required),
    city: str
      .min(2, ErrorsStrings.Minlength2characters)
      .max(48, ErrorsStrings.Maxlength48characters)
      .trim()
      .required(required),
    state: str
      .min(3, ErrorsStrings.Minlength3characters)
      .max(48, ErrorsStrings.Maxlength48characters)
      .required(required),
    zip: str.length(5, ErrorsStrings.InvalidZipCode).required(required),
    firstName: str
      .max(48, ErrorsStrings.Maxlength48characters)
      .matches(/^([A-Za-z ,.'`-]{2,48})$/gm, ErrorsStrings.EnterValid + ' First Name')
      .required(required)
      .trim(),
    lastName: str
      .max(48, ErrorsStrings.Maxlength48characters)
      .matches(/^([A-Za-z ,.'`-]{2,48})$/gm, ErrorsStrings.EnterValid + ' Last Name')
      .required(required)
      .trim(),
    email: email,
    password: str
      .min(5, ErrorsStrings.PasswordShouldMin)
      .max(25, ErrorsStrings.Maxlength25characters)
      .required(required),
    signature: Yup.string()
      .required(required)
      .test('shouldMatchFullName', "Signature doesn't match the name below", function (value) {
        return (
          trimAndLowercase(value) ===
          trimAndLowercase(`${this.parent.firstName} ${this.parent.lastName}`)
        );
      }),
    confirmedPassword: Yup.string()
      .required(required)
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    planCode: str,
  },
  { phoneRequired: true },
);

export const getValidationSchemaSignUpFormWithoutPractice = getYupValidationWithPhone(
  {
    companyName: str.min(3, ErrorsStrings.Minlength3characters).required(required),
    companyWebsite: str
      .matches(
        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9-]+([.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gim,
        'Your company URL must be a valid URL',
      )
      .required(required), //use the required ‘https://’
    address: str
      .min(5, ErrorsStrings.Minlength5characters)
      .max(48, ErrorsStrings.Maxlength48characters)
      .required(required),
    city: str
      .min(2, ErrorsStrings.Minlength2characters)
      .max(48, ErrorsStrings.Maxlength48characters)
      .required(required),
    state: str
      .min(3, ErrorsStrings.Minlength3characters)
      .max(48, ErrorsStrings.Maxlength48characters)
      .required(required),
    zip: str.length(5, ErrorsStrings.InvalidZipCode).required(required),
    firstName: str
      .max(48, ErrorsStrings.Maxlength48characters)
      .matches(/^([A-Za-z ,.'`-]{2,48})$/gm, ErrorsStrings.EnterValid + ' First Name')
      .required(required)
      .trim(),
    lastName: str
      .max(48, ErrorsStrings.Maxlength48characters)
      .matches(/^([A-Za-z ,.'`-]{2,48})$/gm, ErrorsStrings.EnterValid + ' Last Name')
      .required(required)
      .trim(),
    email: email,
    password: str
      .min(5, ErrorsStrings.PasswordShouldMin)
      .max(25, ErrorsStrings.Maxlength25characters)
      .required(required),
    signature: Yup.string()
      .required(required)
      .test('shouldMatchFullName', "Signature doesn't match the name below", function (value) {
        return (
          trimAndLowercase(value) ===
          trimAndLowercase(`${this.parent.firstName} ${this.parent.lastName}`)
        );
      }),
    confirmedPassword: Yup.string()
      .required(required)
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    planCode: str,
  },
  { phoneRequired: true },
);

export const transformeCompanyName = (companyName: string) => {
  return companyName
    .trim()
    .split(' ')
    .join('')
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]/g, '');
};

export const castURLToHttps = (url: string) => {
  const https = 'https://';
  if (url.startsWith('https://')) {
    return url;
  }
  if (url.startsWith('http://')) {
    const actualUrlPart = url.split('http://')[1];
    return `${https}${actualUrlPart}`;
  }
  return `${https}${url}`;
};

export const removeProtocolFromUrl = (url: string = '') => {
  return url.replace(/^https?:\/\//, '');
};

export const getValidationSchemaSignUpFormForQuickSignup = getYupValidationWithPhone(
  {
    companyName: str.min(3, ErrorsStrings.Minlength3characters).required(required),
    firstName: str
      .max(48, ErrorsStrings.Maxlength48characters)
      .matches(/^([A-Za-z ,.'`-]{2,48})$/gm, ErrorsStrings.EnterValid + ' First Name')
      .required(required)
      .trim(),
    lastName: str
      .max(48, ErrorsStrings.Maxlength48characters)
      .matches(/^([A-Za-z ,.'`-]{2,48})$/gm, ErrorsStrings.EnterValid + ' Last Name')
      .required(required)
      .trim(),
    email: email,
    planCode: str,
  },
  { phoneRequired: true },
);

export const getValidationSchemaCompleteProfileForm = getYupValidationWithPhone(
  {
    companyWebsite: str
      .matches(
        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9-]+([.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gim,
        'Your company URL must be a valid URL',
      )
      .required(required), //use the required ‘https://’
    address: str
      .min(5, ErrorsStrings.Minlength5characters)
      .max(48, ErrorsStrings.Maxlength48characters)
      .trim()
      .required(required),
    city: str
      .min(2, ErrorsStrings.Minlength2characters)
      .max(48, ErrorsStrings.Maxlength48characters)
      .trim()
      .required(required),
    state: str
      .min(3, ErrorsStrings.Minlength3characters)
      .max(48, ErrorsStrings.Maxlength48characters)
      .required(required),
    zip: str.length(5, ErrorsStrings.InvalidZipCode).required(required),
    email: email,
    password: str
      .min(5, ErrorsStrings.PasswordShouldMin)
      .max(25, ErrorsStrings.Maxlength25characters)
      .required(required),
    signature: Yup.string()
      .required(required)
      .test('shouldMatchFullName', "Signature doesn't match the name below", function (value) {
        return (
          trimAndLowercase(value) ===
          trimAndLowercase(`${this.parent.firstName} ${this.parent.lastName}`)
        );
      }),
    confirmedPassword: Yup.string()
      .required(required)
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  },
  { phoneRequired: false },
);
