import React, { FC } from 'react';
import { Box, TablePagination } from '@mui/material';

import {
  convertApiPageToFrontEndPage,
  convertFrontEndPageToApiPage,
} from '../../../services/utilities';
import { useTypedSelector } from '../../../store';
import { useStyles } from './Pagination.styles';
import clsx from 'clsx';
import { PaginationSelectIcon } from '../../assets/PaginationSelectIcon';

interface PaginatorProps {
  totalPages: number;
  totalItems: number;
  page: number;
  size: number;
  onChangeSize: (size: number) => void;
  onPageClick: (page: number) => void;
  className?: string;
  labelRowsPerPage?: string;
  roundedButtons?: boolean;
  isPaginationDisabled?: boolean;
}

export const Pagination: FC<PaginatorProps> = ({
  page,
  size,
  onChangeSize,
  totalItems,
  onPageClick,
  className,
  labelRowsPerPage,
  roundedButtons,
  isPaginationDisabled,
}) => {
  const styles = useStyles();
  const handleChangePage = (event: React.ChangeEvent<unknown> | null, newPage: number) => {
    onPageClick(convertApiPageToFrontEndPage(newPage));
  };

  const { isLoading: selectedIsLoading } = useTypedSelector((state) => state.selectedRows);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const rowsNumber = parseInt(event.target.value, 10);
    onChangeSize(rowsNumber);
  };

  const maxPage = Math.ceil(totalItems / size);
  return (
    <>
      {!selectedIsLoading && (
        <TablePagination
          component="div"
          count={totalItems}
          page={convertFrontEndPageToApiPage(page)}
          onPageChange={handleChangePage}
          rowsPerPage={size}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className={clsx(className, styles.root)}
          labelRowsPerPage={labelRowsPerPage}
          backIconButtonProps={{
            disabled: isPaginationDisabled || page === 1,
            className: roundedButtons ? styles.navButton : '',
            sx: {
              '& svg': {
                fontSize: '22px',
              },
            },
          }}
          nextIconButtonProps={{
            disabled: isPaginationDisabled || page === maxPage,
            className: roundedButtons ? styles.navButton : '',
            LinkComponent: () => <Box className={styles.navButton} />,
            sx: {
              marginRight: '12px',
              '& svg': {
                fontSize: '22px',
              },
            },
          }}
          SelectProps={{
            className: styles.select,
            IconComponent: () => (
              <Box className={styles.selectIconWrapper}>
                <PaginationSelectIcon />
              </Box>
            ),
            MenuProps: {
              sx: {
                '& .MuiPaper-root': {
                  zoom: '0.9',
                  marginLeft: '8px',
                },
                '& .MuiMenuItem-root': { fontSize: '12px' },
              },
            },
          }}
        />
      )}
    </>
  );
};
