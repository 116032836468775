import { FC } from 'react';
import { Box } from '@mui/material';
import { useSignUpStyles } from './SignUp.style';
import { SignUpForm } from './SignUpForm';

export const SignUp: FC = () => {
  const classes = useSignUpStyles();
  return (
    <Box className={classes.root}>
      <SignUpForm />
    </Box>
  );
};
