import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  root: {
    '& .react-toast-notifications__toast__icon-wrapper': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .react-toast-notifications__toast__content': {
      display: 'flex',
      alignItems: 'center',
    },
  },
}));
