import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AffiliateEndpoints } from '../../api/endpoints';
import { httpClient } from '../../services/httpClient/httpClient';
import {
  AffiliateModel,
  GetAffiliateByIdOrCodeRequest,
  GetAffiliateByIdOrCodeResponse,
  GetAffiliatesRequest,
  GetAffiliatesResponse,
  PostAffiliateRequest,
  PostAffiliateResponse,
  PutAffiliateResponse,
  PutAffiliatesRequest,
} from '../../api/models/affiliates';

const initialState = {
  affiliates: [] as AffiliateModel[],
  activeAffiliate: undefined as AffiliateModel | undefined,
  isLoading: false,
  isError: false,
};

interface GetAffiliatesArgs {
  distributorIds: Array<GetAffiliatesRequest['distributorId']>;
}

export const getAffiliatesByDistributorIds = createAsyncThunk(
  'affiliates/getAffiliatesByDistributorIds',
  async ({ distributorIds }: GetAffiliatesArgs, { rejectWithValue }) => {
    try {
      const promises = distributorIds.map((distributorId) => {
        return httpClient.get<GetAffiliatesRequest, GetAffiliatesResponse>({
          url: AffiliateEndpoints.GetAffiliates,
          requiresToken: true,
          params: {
            distributorId,
          },
        });
      });

      const res = await Promise.all(promises);
      return res.flat();
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getAffiliates = createAsyncThunk(
  'affiliates/getAffiliates',
  async ({ distributorId }: { distributorId?: string }, { rejectWithValue }) => {
    try {
      return await httpClient.get<GetAffiliatesRequest | {}, GetAffiliatesResponse>({
        url: AffiliateEndpoints.GetAffiliates,
        requiresToken: true,
        params: distributorId
          ? {
              distributorId,
            }
          : {},
      });
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getAffiliateByIdOrCode = createAsyncThunk(
  'affiliate/getAffiliateByIdOrCode',
  async ({ idOrCode }: GetAffiliateByIdOrCodeRequest, { rejectWithValue }) => {
    try {
      return httpClient.get<GetAffiliateByIdOrCodeRequest, GetAffiliateByIdOrCodeResponse>({
        url: AffiliateEndpoints.GetAffiliateByIdOrCode.replace(':idOrCode', idOrCode),
        requiresToken: true,
      });
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const createNewAffiliate = createAsyncThunk(
  'affiliates/createNewAffiliate',
  async ({ payload }: PostAffiliateRequest, { rejectWithValue }) => {
    try {
      return httpClient.post<PostAffiliateRequest['payload'], PostAffiliateResponse>({
        url: AffiliateEndpoints.PostAffiliates,
        requiresToken: true,
        payload,
      });
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const updateAffiliate = createAsyncThunk(
  'affiliates/updateAffiliate',
  async ({ id, payload }: PutAffiliatesRequest, { rejectWithValue }) => {
    try {
      return httpClient.put<PutAffiliatesRequest['payload'], PutAffiliateResponse>({
        url: AffiliateEndpoints.PutAffiliates.replace(':id', id),
        requiresToken: true,
        payload,
      });
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

const affiliatesSlice = createSlice({
  name: 'affiliates',
  initialState,
  reducers: {
    reset(state) {
      state = initialState;
    },
    setActiveAffiliate(state, action: PayloadAction<AffiliateModel | undefined>) {
      state.activeAffiliate = action.payload
    }
  },
  extraReducers: (reducersBuilder) => {
    reducersBuilder.addCase(getAffiliatesByDistributorIds.pending, (state) => {
      state.isLoading = true;
    });
    reducersBuilder.addCase(getAffiliatesByDistributorIds.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
    reducersBuilder.addCase(getAffiliatesByDistributorIds.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isError = false;
      state.affiliates = payload;
    });
    //getAffiliates
    reducersBuilder.addCase(getAffiliates.pending, (state) => {
      state.isLoading = true;
    });
    reducersBuilder.addCase(getAffiliates.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
    reducersBuilder.addCase(getAffiliates.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isError = false;
      state.affiliates = payload;
    });
  },
});

export const { reset, setActiveAffiliate } = affiliatesSlice.actions;

export default affiliatesSlice.reducer;
