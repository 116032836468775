import { useTypedSelector } from '../../../../store';
import { useClientType } from '../../../../services/hooks/useClientType';
import { default_sms_invite, default_sms_invite_TruBlu } from './InviteDefaultSMSTemplates';
import { default_sms_reward, default_sms_reward_TruBlu } from './RewardDefaultSMSTemplates';
import {
  awareness_default_sms_invite,
  awareness_default_sms_invite_TruBlu,
} from './AwarenessInviteDefaultSMSTemplates';
import {
  awareness_default_sms_reward,
  awareness_default_sms_reward_TruBlu,
} from './AwarenessRewardDefaultSMSTemplates';

export const useSetDefaultSMSTemplates = (isAwarenessCampaign?: boolean) => {
  const { isNullReward } = useTypedSelector((state) => state.createCampaign);

  const { isHealthCare } = useClientType();

  const isNoIncentive = isAwarenessCampaign || isNullReward;

  const defaultSmsType = `defaultInvite`;

  const defaultInviteMessage = isHealthCare
    ? default_sms_invite_TruBlu[defaultSmsType]
    : default_sms_invite[defaultSmsType];

  const defaultRewardMessage = isHealthCare
    ? default_sms_reward_TruBlu[defaultSmsType]
    : default_sms_reward[defaultSmsType];

  const awareness_defaultInviteMessage = isHealthCare
    ? awareness_default_sms_invite_TruBlu[defaultSmsType]
    : awareness_default_sms_invite[defaultSmsType];

  const awareness_defaultRewardMessage = isHealthCare
    ? awareness_default_sms_reward_TruBlu[defaultSmsType]
    : awareness_default_sms_reward[defaultSmsType];

  return {
    defaultInviteMessage: isNoIncentive ? awareness_defaultInviteMessage : defaultInviteMessage,
    defaultRewardMessage: isNoIncentive ? awareness_defaultRewardMessage : defaultRewardMessage,
  };
};
